import React from "react";
import Preloader from "./components/preloader";
import ShiftingDropDown from "./components/shiftingDropDown";
import AuroraHero from "./components/AuroraHero";
import CursorAnim from "./components/cursorAnim";
import TabComponent from "./components/TabComponent";
import { useEffect } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./components/ui/accordion";

import RevealLinks from "./components/links";

// import TextParallaxContentExample from "./components/Features"

const App = () => {
  useEffect(() => {
    document.title = "Femto";
  }, []);

  return (
    <>
      <title>Femto</title>
      <Preloader />
      <CursorAnim />
      <div className="app-container">
        <ShiftingDropDown />
        <AuroraHero />
        <div className="bg-[#020617] pt-8">
          <TabComponent />
        </div>

        <div className="bg-[#020617]">
          <div className="pt-14 md:pt-28">
            <h1 className="text-white font-ttsquare text-3xl md:text-4xl lg:text-5xl text-center">
              Let's Solve some Question
            </h1>
          </div>
          <Accordion
            type="single"
            collapsible
            className="bg-[#020617] font-varelaround pt-10 md:pt-20 px-10 md:px-32 lg:px-72"
          >
            <AccordionItem value="item-1">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                How does the "Anybody Can Drive" (ABCD) feature work?
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                Everyday drivers can offer vacant seats for rides on FEMTO. If
                you're traveling with empty seats, list them on the app, and
                users needing a ride can request a seat, allowing you to earn
                money.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-2">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                What is the "Call and Book" feature
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                FEMTO allows users to book rides via phone calls for those who
                prefer traditional booking methods. This enhances accessibility
                and convenience.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                How does the fare bargaining feature work?
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                Riders and drivers can negotiate the fare before confirming the
                trip, promoting transparency and flexibility in pricing.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-4">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                What is the SOS Safety feature?
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                FEMTO integrates an SOS safety system in collaboration with the
                government of Telangana, allowing users to send immediate alerts
                to nearby app users and authorities in emergencies.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-5">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                What are the EMI payment options?
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                FEMTO offers three EMI plans for users with good ride scores:
                Monthly Ledger System, Six-Month Plan, and Yearly Plan,
                providing flexible payment options.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-6">
              <AccordionTrigger className="text-white text-lg text-left md:text-[1.32rem]">
                Is FEMTO available in all locations?
              </AccordionTrigger>
              <AccordionContent className="text-zinc-100 text-sm md:text-lg">
                FEMTO is expanding its services, and will soon be available in
                all locations.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="bg-[#020617]">
          <h1 className="text-[3rem] md:text-[5rem] lg:text-[7rem] text-violet-300 text-center pt-28 font-ttsquare">
            Contact Us
          </h1>

          <div className="grid grid-cols-2">
            <div className="sm:text-4xl md:text-6xl lg:text-8xl px-8 py-32">
              <h1 className="text-slate-300 font-ttsquare">Femto</h1>
              <h1 className="text-slate-300 pt-4 sm:text-2xl md:text-4xl lg:text-6xl font-ttsquare">
                femtopvtltd@gmail.com
              </h1>
            </div>
            <RevealLinks />
          </div>
        </div>

        <div className="place-content-center text-violet-400 font-varelaround bg-[#020617] py-10">
          <h1 className="text-center text-lg">
            Copyright © 2024 | Designed and Developed by Femto Team
          </h1>
        </div>
      </div>
    </>
  );
};

export default App;
