import React, { useState, useRef, useEffect } from 'react';
import { FiTarget, FiCheckCircle, FiUsers,FiDribbble } from "react-icons/fi";

import './TabComponent.css';

const tabs = [
  {
    name: 'First Feature',
    icon: <FiTarget className="h-6 w-6" />,
    description: 'Call and Book (CAB) Feature',
    demo: (
      <div className="bg-[#27272a] backdrop-blur-8 text-white p-4 rounded-xl shadow-md relative terminal">
        <div className="absolute top-0 left-0 right-0 h-8 bg-[#161617] flex items-center px-3 rounded-t-xl">
          <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
        </div>
        <div className="pt-5 font-varelaround">
          <span className='text-green-400 text-[1.6875rem]'>~</span> 
          <span className='font-ttsquare pl-2 text-[1.6875rem]'>Call and Book (CAB) Feature:
          </span>
          <br /><br />
          <span className='text-lg'>
          The Call and Book (CAB) feature in FEMTO allows users to book rides by making a phone call, providing an alternative to app-based booking. This feature is designed to cater to users who prefer or require a more traditional method of arranging transportation. By offering a call-in option, FEMTO ensures that its services are accessible to a wider audience, including those who may not be comfortable using mobile apps or have limited access to digital platforms.</span>
        </div>
      </div>
    ),
  },
  {
    name: 'Second Feature',
    icon: <FiCheckCircle className="h-6 w-6" />,
    description: 'Anybody Can Drive (ABCD) Feature',
    demo: (
        <div className="bg-[#27272a] backdrop-blur-8 text-white p-4 rounded-xl shadow-md relative terminal">
        <div className="absolute top-0 left-0 right-0 h-8 bg-[#161617] flex items-center px-3 rounded-t-xl">
          <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
        </div>
        <div className="pt-5 font-varelaround">
          <span className='text-green-400 text-[1.6875rem]'>~</span> 
          <span className='font-ttsquare pl-2 text-[1.6875rem]'>Anybody Can Drive (ABCD) Feature:          </span>
          <br /><br />
          <span className='text-lg'>
            
          The Anybody Can Drive (ABCD) feature in FEMTO allows everyday drivers to offer their vacant seats to others who need a ride. For instance, if you're driving from Vijayawada to Hyderabad with empty seats, you can list them on FEMTO. Users looking for a ride to Hyderabad can request a seat through the app. This feature not only makes transportation more accessible but also enables drivers to earn money from their daily commutes. The built-in SOS feature ensures the safety of all participants.
            
           </span>
        </div>
      </div>
    ),
  },
  {
    name: 'Third Feature',
    icon: <FiUsers className="h-6 w-6" />,
    description: 'Fare Bargain Feature',
    demo: (
        <div className="bg-[#27272a] backdrop-blur-8 text-white p-4 rounded-xl shadow-md relative terminal">
        <div className="absolute top-0 left-0 right-0 h-8 bg-[#161617] flex items-center px-3 rounded-t-xl">
          <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
        </div>
        <div className="pt-5 font-varelaround">
          <span className='text-green-400 text-[1.6875rem]'>~</span> 
          <span className='font-ttsquare pl-2 text-[1.6875rem]'>Fare Bargain Feature: </span>
          <br /><br />
          <span className='text-lg'>

          The Fare Bargain feature in FEMTO allows riders and drivers to negotiate the fare before confirming a trip. This promotes transparency and flexibility, enabling both parties to agree on a price that suits their needs. Whether you're looking for a more affordable ride or trying to maximize your earnings as a driver, this feature provides a platform for fair and open discussions, ensuring that both parties reach a mutually beneficial agreement before the journey begins.

          </span>
        </div>
      </div>
    ),
  },

  {
    name: 'Fourth Feature',
    icon: <FiDribbble className="h-6 w-6" />,
    description: 'EMI Payment Feature:',
    demo: (
        <div className="bg-[#27272a] backdrop-blur-8 text-white p-4 rounded-xl shadow-md relative terminal">
        <div className="absolute top-0 left-0 right-0 h-8 bg-[#161617] flex items-center px-3 rounded-t-xl">
          <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full mr-2"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
        </div>
        <div className="pt-5 font-varelaround">
          <span className='text-green-400 text-[1.6875rem]'>~</span> 
          <span className='font-ttsquare pl-2 text-[1.6875rem]'>EMI Payment Feature:</span>
          <br /><br />
          <span className='text-lg'>
          The EMI (Equated Monthly Installment) Payment feature in FEMTO offers users flexible payment options based on their ride history and score. Users with good ride scores can opt for three different payment plans:
          <div>1. ⁠Monthly Ledger System: Pay the total ride amount at the start of the next month without any interest.
          </div>
            <div className='pt-5'> 2. Six-Month Plan: Spread payments over six months with a small interest rate.            </div>
            <div className='pt-5'>3. Yearly Plan: Opt for smaller monthly payments over a year with an interest rate           .</div>

            This feature makes rides more affordable and encourages responsible usage.

          </span>
        </div>
      </div>
    ),
  },
];

const TabComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabRefs = useRef([]);

  useEffect(() => {
    const updateIndicator = () => {
      const currentTab = tabRefs.current[selectedTab];
      if (currentTab) {
        const tabRect = currentTab.getBoundingClientRect();
        const containerRect = currentTab.parentElement.getBoundingClientRect();
        setIndicatorStyle({
          width: tabRect.width,
          left: tabRect.left - containerRect.left - 16,
        });
      }
    };
    updateIndicator();
    window.addEventListener('resize', updateIndicator);
    return () => window.removeEventListener('resize', updateIndicator);
  }, [selectedTab]);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="flex flex-col items-center w-full bg-gradient-to-b bg-[#020617]">
      <div className="relative flex space-x-4  mb-4 overflow-x-auto text-white">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`flex items-center p-4 tab-button font-varelaround ${selectedTab === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
            ref={(el) => (tabRefs.current[index] = el)}
          >
            <div className={`icon-wrapper ${selectedTab === index ? 'icon-active' : ''}`}>{tab.icon}</div>
            <span className="hidden sm:inline">{tab.description}</span>
          </button>
        ))}
        <div
          className="absolute bottom-0 h-1 bg-gradient-to-br from-[#5B5BE7] to-[#443ACC] transition-all duration-300"
          style={{ width: `${indicatorStyle.width}px`, left: `${indicatorStyle.left}px` }}
        ></div>
      </div>
      <div className={`px-5 md:px-0 mt-8 w-full md:w-3/4`}>
        {tabs[selectedTab].demo}
      </div>
    </div>
  );
};

export default TabComponent;
